<template>
<div id="projects">
	<vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
    <section id="inner-headline">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="pageTitle">About Us</h2>
                </div>
            </div>
        </div>
    </section>
    <section id="content">
        <div class="container">
			<div class="about">
				<div class="row">
					<div v-html="about.long_description" class="col-md-12">
					</div>
				</div>
				<div v-if="team.length">
					<div class="block-heading-six">
						<h4 class="bg-color">Our Team</h4>
					</div><br>
					<div class="team-six">
						<div class="row">
							<div v-for="member in team" :key="member.id" class="col-md-3 col-sm-6">
								<div class="team-member">
									<img style="height: 160px; width: 100%;" class="img-responsive" :src="$uploadsURL+'team/'+member.image" alt="">
									<h4>{{ member.name }}</h4>
									<span class="deg">{{ member.designation }}</span> 
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
</template>

<script type="text/javascript" scoped>
export default{
	name: "AboutView",
	data(){
		return {
			about: {
				long_description: "",
			},
			team: [],
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_about",
		}).then((res) => {
			this.about = res.data;
			this.$axios({
				method: "get",
				url: this.$apiURL+"get_team",
			})
			.then((res) => {
				this.team = res.data;
				this.$refs.topProgress.done();
			});
		});
	}
}
</script>

<style type="text/css" scoped>
</style>